import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {ICD} from './webpages/ICD';
import {ICU} from './webpages/ICU';
import {Covid} from './webpages/Covid';
import {Server} from './webpages/server'
import {Client as Styletron} from "styletron-engine-atomic";
import {BasicModal} from './webpages/simple'
import {Test} from "./webpages/test";
import {NAFLD} from "./webpages/NAFLD";
import {PRO} from "./webpages/PRO";
import {CAL} from "./webpages/CAL";
import {NII} from "./webpages/NII";

let root;
console.log(window.location.host)
if (window.location.host.includes("localhost") || window.location.host.includes("127.0.0.1")) {
    root = "http://localhost:8000";
} else if (window.location.host.includes("210.28.133.103")) {
    root = 'http://210.28.133.103:8000'
} else if (window.location.host.includes("210.28.135.55")) {
    root = 'http://210.28.133.103:8000'
} else if (window.location.host.includes("rlmedai.com")) {
    root = "http://www.rlmedai.com:8000"
} else if (window.location.host.includes("rlmedai.top")) {
    root = "http://www.rlmedai.top:8000"
}
// change allowed_host in django for server with new ip

console.log(root);
const server_num = 12;
const engine = new Styletron();
const update_freq=10*1000; 
console.log(window.location.href);

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/test" element={<Test root={root} engine={engine} />} exact/>
            <Route path="/NAFLD" element={<NAFLD root={root} engine={engine} />} exact/>
            <Route path="/ICD" element={<ICD root={root} engine={engine}/>} exact/>
            <Route path="/Covid" element={<Covid root={root} engine={engine}/>} exact/>
            <Route path="/CAL" element={<CAL root={root} engine={engine}/>} exact/>
            <Route path="/PRO" element={<PRO root={root} engine={engine}/>} exact/>
            <Route path="/TSOFA" element={<ICU root={root} engine={engine}/>} exact/>
            <Route path="/NII" element={<NII root={root} engine={engine}/>} exact/>
            {root.includes("rlmedai")? "" : <Route path="/server" element={<Server server_num={server_num} root={root} engine={engine} update_freq={update_freq}/>} exact/>}
            {/*{root.includes("rlmedai")? "" : <Route path="/ICD" element={<ICD root={root} engine={engine}/>} exact/>}*/}
            {/*<Route path="/simple" element={<BasicModal server_num={server_num} root={root} engine={engine}/>} exact/>*/}
        </Routes>
    </BrowserRouter>,
    document.getElementById('root')
)




